import React, { useState } from "react";
import classNames from "classnames";
import { graphql, PageProps } from "gatsby";

import { Page } from "../components/Page";
import { Collapsible } from "../components/Common/Collapsible";
import { Markdown } from "../components/Common/Markdown";

import { Icon } from "../components/Common/Icon";
import { Title } from "../components/Common/Title";

import * as styles from "../styles/pages/Contact.module.scss";
import { ContactForm } from "../components/ContactForm";
import { SEO } from "../components/SEO";

interface FAQItemData {
  answer: string;
  question: string;
}

interface FAQQueryProps {
  content: {
    title: string;
    items: FAQItemData[];
  };
}

const FAQItem: React.FC<FAQItemData> = ({ question, answer }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className={classNames(styles.item, { [styles.open]: open })}>
      <div className={styles.itemTop} onClick={() => setOpen(!open)}>
        <span className={styles.itemTitle}>{question}</span>

        <Icon name="plus" className={styles.itemIcon} />
      </div>

      <Collapsible open={open}>
        <Markdown className={styles.itemAnswer}>{answer}</Markdown>
      </Collapsible>
    </div>
  );
};

const ContactPage: React.FC<PageProps<FAQQueryProps>> = ({ data }) => {
  return (
    <>
      <SEO title="Contact" />
      <Page title="Contact" withSocial>
        <div className={styles.contactForm}>
          <ContactForm />
        </div>

        <div className={styles.faq}>
          <Title>FAQ</Title>
          {data.content.items.map((item) => (
            <FAQItem key={item.question} {...item} />
          ))}
        </div>
      </Page>
    </>
  );
};

export const query = graphql`
  query FAQQuery {
    content: sanityFaq {
      id
      title
      items {
        answer
        question
      }
    }
  }
`;

export default ContactPage;
