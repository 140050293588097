import React from "react";
import classNames from "classnames";
import { GatsbyImageProps, GatsbyImage, getImage } from "gatsby-plugin-image";

import { Container } from "../Common/Container";
import { Markdown } from "../Common/Markdown";
import { Title } from "../Common/Title";

import * as styles from "./Page.module.scss";
import { SocialLinks } from "../Common/SocialLinks";

interface PageProps {
  className?: string;
  title: string;
  children?: string | React.ReactNode;
  image?: {
    asset: GatsbyImageProps["image"];
  };
  withSocial?: boolean;
}

const Page: React.FC<PageProps> = ({
  className,
  children,
  title,
  image,
  withSocial = false,
}) => {
  return (
    <Container
      tagName="section"
      className={classNames(
        styles.page,
        { [styles.withSocial]: withSocial },
        className
      )}
    >
      <div className={styles.left}>
        <Title size="large">{title}</Title>

        {withSocial && <SocialLinks className={styles.links} />}

        {image && <GatsbyImage image={getImage(image.asset)} alt="" />}
      </div>
      <div className={styles.content}>
        {typeof children === "string" ? (
          <Markdown>{children}</Markdown>
        ) : (
          children
        )}
      </div>
    </Container>
  );
};

export { Page };
