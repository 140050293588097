import React, { useCallback, useEffect, useState } from "react";
import { Button } from "../Common/Button";

import * as styles from "./ContactForm.module.scss";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

const ContactForm: React.FC = () => {
  const [error, setError] = useState<false | string>(false);
  const [success, setSuccess] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const onSubmit = useCallback<React.FormEventHandler<HTMLFormElement>>((e) => {
    e.preventDefault();
    const form = e.target as HTMLFormElement;

    const email = form.email.value;
    const firstName = form["first-name"].value;
    const message = form["message"].value;

    if (!email || !message) {
      setError("Please fill out the form");
      return;
    }

    setSubmitting(true);

    fetch("/", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: encode({
        "form-name": form["form-name"].value,
        email,
        "first-name": firstName,
        message,
      }),
    })
      .then(() => setSuccess(true))
      .catch((e) => {
        setError(
          "There was an error! Maybe e-mail directly to jon@jonburgerman.com"
        );
      })
      .finally(() => {
        setSubmitting(false);
      });
  }, []);

  useEffect(() => {
    if (error) {
      let timerId = setTimeout(() => {
        setError(false);
      }, 2500);

      return () => clearTimeout(timerId);
    }
  }, [error]);

  return (
    <form
      onSubmit={onSubmit}
      className={styles.form}
      method="post"
      netlify-honeypot="bot-field"
      data-netlify="true"
      name="contact"
    >
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="form-name" value="contact" />

      <label>
        <span>First Name</span>
        <input disabled={submitting} name="first-name" type="text" />
      </label>
      <label>
        <span>E-mail</span>
        <input disabled={submitting} name="email" type="text" />
      </label>
      <label>
        <span>Message</span>
        <textarea
          disabled={submitting}
          name="message"
          placeholder="Type your message here..."
          rows={8}
        ></textarea>
      </label>

      {success && (
        <span className={styles.success}>Thanks! Your message was sent</span>
      )}

      {error && <span className={styles.error}>{error}</span>}

      <Button
        disabled={submitting || success}
        className={styles.submit}
        forceButton
      >
        Submit
      </Button>
    </form>
  );
};

export { ContactForm };
